import { styled } from "styled-components";

export const StyledAboutus = styled.div`
  color: #000000;
  padding-top: 50px;
  margin: auto;
  padding: 30px;
  text-align: justify;

  & h1 {
    font-size: 32px;
    text-align: center;
  }

  & p {
    font-size: 24px;
    padding: 1rem;
    margin-top: 10px;
    text-align: justify; /* Ensures text justification */
    
    @media screen and (max-width: 768px) {
      font-size: 16px;
      padding: 0rem !important;
      text-align: justify; /* Retain justification for smaller screens */
    }
  }

  @media screen and (max-width: 768px) {
    padding-top: 20px !important;
  }
`;

export const SmallBar = styled.div`
  height: 3px;
  width: 75px;
  background: #F43434;
  order: 1;
  margin: auto;
`;
