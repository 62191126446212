export const NavData = [
    {   key:'1',
        name:'HOME',
        path:'#home',
    },
    {   key:'2',
        name:'SHOP',
        path:'#shop',
    },
    {   key:'3',
        name:'ABOUT',
        path:'#about'
    },
    {   key:'4',
        name:'CONTACT',
        path:'#contact'
    },
//     {   key:'5',
//         name:'LOGIN',
//         path:'/login'
// },

];

// export const NavLinks = [
//     {
//         key:'1',
//         name:'REGISTER',
//         path:'/addcustomer'
//     },
//     {
//         key:'2',
//         name:'LOGIN',
//         path:'/login'
//     }
// ]